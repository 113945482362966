import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./CustomCursor.scss";

export default function CustomCursor() {
  const smallCursor = useRef(null);
  const bigCursor = useRef(null);

  useEffect(() => {
    document.addEventListener("mousemove", (e) => {
      const { clientX, clientY } = e; 
      onMouseMove(clientX, clientY);
    });
  }, []);

  // Move the cursor
  function onMouseMove(mouseX, mouseY) {
    gsap.to(smallCursor.current, {
      duration: 0.1,
      x: mouseX,
      y: mouseY 
    });
    gsap.to(bigCursor.current, {
      duration: 0.5,
      x: mouseX - 10,
      y: mouseY - 5,
    }); 
  }

  return (
    <div className="cursor"> 
      <div className="cursor__ball cursor__ball--small" ref={smallCursor}>
        <svg height="10" width="10">
          <circle cx="5" cy="5" r="4"></circle>
        </svg>
      </div>
      <div className="cursor__ball cursor__ball--big" ref={bigCursor}>
        <svg height="30" width="100">
          <circle cx="15" cy="15" r="12"></circle>
        </svg>
      </div> 
    </div>
  );
}
