import ProjectsItem from "./ProjectsItem";
import "./Projects.scss";

export default function Projects(props) {
  const { projectContentRef, projectTitleRef, projectTitleStyle, section } =
    props;

  const quidest = ["firebase.png", "node.png", "reactjs.png", "tensorflow.png"];
  const scheduler = ["node.png", "reactjs.png"];
  const buyandsell = ["node.png", "postgresql.png", "jquery.png"];
  const twitter = ["node.png", "jquery.png"];

  return (
    <section className="projects-container" ref={section}>
      <div className={projectTitleStyle} ref={projectTitleRef}>
        PROJECTS
      </div>
      <div className="projects-container__content" ref={projectContentRef}>
        <ProjectsItem
          title={"Quid-est?"}
          description={
            "Quid-est allows you to upload an image from your file system or phone camera and translate it into a language of your choice. It implements the TensorflowJS machine learning libraries to parse the content of the image and provide its top three best guesses for the correct image label."
          }
          imgURL={"assets/img/quidest.png"}
          stack={quidest}
          github={"https://github.com/paolagd/quid-est"}
        />
        <hr />
        <ProjectsItem
          title={"Interview Scheduler"}
          description={
            "Interview scheduler is an App that helps with the logistics of scheduling interviews. It provides information about your week, how many appointments have been scheduled, for what student and with what interviewer, and how many spots left there are for each day."
          }
          imgURL={"assets/img/interviewer.png"}
          stack={scheduler}
          github={"https://github.com/paolagd/scheduler"}
        />
        <hr />
        <ProjectsItem
          title={"Buy & Sell"}
          description={
            "An app where you can put different types of things up for sale. It allows buyers find the items they are looking for quickly, and easily contact sellers."
          }
          imgURL={"assets/img/store.png"}
          stack={buyandsell}
          github={"https://github.com/paolagd/Lightjiji"}
        />
        <hr />
        <ProjectsItem
          title={"Tweetter"}
          description={
            "Simple, single-page Twitter clone. You can share anything you want and click on TWEET !"
          }
          imgURL={"assets/img/twitter.png"}
          stack={twitter}
          github={"https://github.com/paolagd/tweeter"}
        />
      </div>
    </section>
  );
}
