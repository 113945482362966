import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./Header.scss";

export default function Header(props) {
  const { scrollToSection, projectSection, contactSection, aboutSection } =
    props;

  const titleRef = useRef(null);

  const titleAnimation = () => {
    const vh = window.innerHeight / 4;

    if (window.scrollY < vh) {
      gsap.to(titleRef.current, { duration: 1, color: "#4056A1" });
    } else if (window.scrollY > vh) {
      gsap.to(titleRef.current, { duration: 1, color: "#F13C20" });
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", titleAnimation);
    return () => document.removeEventListener("scroll", titleAnimation);
  }, []);

  return (
    <section className="header-container">
      <nav className="header-container__menu">
        <div onClick={() => scrollToSection(aboutSection.current)}>About</div>
        <div onClick={() => scrollToSection(projectSection.current)}>
          Projects
        </div>
        <div onClick={() => scrollToSection(contactSection.current)}>
          Contact
        </div>
      </nav>

      <div className="header-container__title">
        <div className="header-container__title__name" ref={titleRef}>
          PAOLA
          <span className="header-container__title__lastname">GARCIA</span>
        </div>
        <h3>Full-stack Developer</h3>

        <div className="header-container__title__contact">
          <a href="https://github.com/paolagd">
            <i className="fab fa-github"></i>
          </a>
          <a href="https://www.linkedin.com/in/paola-garcia-diaz/">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://twitter.com/ThatCodingStuff">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>

      <div className="header-container__scroll-down">
        <button onClick={() => scrollToSection(aboutSection.current)}>
          <h3>Scroll Down</h3>
          <i className="fas fa-angle-double-down"></i>
        </button>
      </div>
    </section>
  );
}
