import "./ProjectsItem.scss";

export default function ProjectsItem(props) {
  const { title, description, imgURL, stack, github } = props;

  const techStack = stack.map((stack, index) => (
    <img
      key={index}
      className="project-item__tech-stack"
      src={`assets/img/techstack/${stack}`}
      alt="techstack"
    ></img>
  ));

  return (
    <div className="project-item">
      <a href={github}>{title}</a>
      <br />
      <a href={github}>
        <img src={imgURL} alt="project-img"></img>
      </a>
      <br />
      <br />
      <p>{description}</p>
      <br />
      <div>{techStack}</div>
    </div>
  );
}
