import { useEffect, useRef, useState } from "react";
import Header from "./components/Header.js";
import About from "./components/About.js";
import Projects from "./components/Projects.js";
import Contact from "./components/Contact.js";
import CustomCursor from "./components/CustomCursor.js";
import Articles from "./components/Articles.js";
import { gsap } from "gsap";
import * as ScrollToPlugin from "gsap/ScrollToPlugin";

function App() {
  const projectTitleDiv = useRef();
  const projectContentDiv = useRef();
  const contactDivRef = useRef();
  const [projectTitleStyle, setProjectTitleStyle] = useState(
    "projects-container__title"
  );
  const projectsSection = useRef(null);
  const aboutSection = useRef();

  gsap.registerPlugin(ScrollToPlugin);

  const handleScroll = () => {
    //Getting DOM elements from Refs
    const contentDiv = projectContentDiv.current.getBoundingClientRect();
    const titleDiv = projectTitleDiv.current.getBoundingClientRect();
    const contactDiv = contactDivRef.current.getBoundingClientRect();
    //To check if the title div is close to the contact div
    const contactDivProximity = window.scrollY + titleDiv.height;
    //Pixel where the contact div starts
    const contactDivTop = window.document.body.offsetHeight - contactDiv.height;

    if (contentDiv.top > 0) {
      setProjectTitleStyle("projects-container__title");
    } else if (contactDivProximity < contactDivTop) {
      setProjectTitleStyle("projects-container__title fixed");
    } else if (contactDivProximity >= contactDivTop) {
      setProjectTitleStyle("projects-container__title endOfContent");
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  //Scrolls to the correct section in a slow motion
  const scrollToSection = (element) => {
    gsap.to(window, { duration: 2, scrollTo: element });
  };

  return (
    <div className="App">
      <CustomCursor />
      <Header
        scrollToSection={scrollToSection}
        projectSection={projectsSection}
        aboutSection={aboutSection}
        contactSection={contactDivRef}
      />
      <About section={aboutSection} />
      <Articles />
      <Projects
        section={projectsSection}
        projectContentRef={projectContentDiv}
        projectTitleRef={projectTitleDiv}
        projectTitleStyle={projectTitleStyle}
      />
      <Contact contactDivRef={contactDivRef} />
    </div>
  );
}

export default App;
