import "./Articles.scss";

export default function Articles() {
  return (
    <section className="articles-container">
      <div className="articles-container__title">ARTICLES</div>
      <div className="articles-container__content">
        <a href={"https://medium.com/@pacri14/expressjs-vs-koajs-9b3f5abbb110"}>
          <div className="articles-container__content__article">
            <i className="far fa-newspaper"></i>

            <h3 className="articles-container__article__url">
              ExpressJS vs. KoaJS
            </h3>
          </div>
        </a>
      </div>
    </section>
  );
}
