import "./About.scss";

export default function About(props) {
  const { section } = props;
  return (
    <section className="about-container" ref={section}>
      <div className="about-container__content">
        <img src="assets/img/profile.jpeg" alt="profile-img"></img>
        <br />
        <h3>
          Hi, my name is <span> Paola Garcia</span>
        </h3>
        <h4>I am a Full Stack Developer living in Toronto, Ontario.</h4>
        <h4>
          I am curious, detail-oriented and passionate about writing clean code.
        </h4>
        <br />
        <h4>I am also a dance lover and a plant mom 🌱</h4>
      </div>
    </section>
  );
}
