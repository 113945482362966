import "./Contact.scss";

export default function Contact(props) {
  const { contactDivRef } = props;
  return (
    <section className="contact-container" ref={contactDivRef}>
      <h2>CONTACT ME!</h2>
      <div className="contact-container__links">
        <a href="https://github.com/paolagd">
          <i className="fab fa-github"></i>
        </a>
        <a href="https://www.linkedin.com/in/paola-garcia-diaz/">
          <i className="fab fa-linkedin"></i>
        </a>
        <a href="https://twitter.com/ThatCodingStuff">
          <i className="fab fa-twitter"></i>
        </a>
        <a
          href={`https://www.canva.com/design/DAEsL0iQD7U/q33E954w-xKc2kpasZ8fTQ/view?utm_content=DAEsL0iQD7U&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton`}
        >
          <i className="fas fa-file"></i>
        </a>
      </div>
      <br />
      <a href={`mailto:pacri14@gmail.com?subject='Contact Paola Garcia'}`}>
        pacri14@gmail.com
      </a>
    </section>
  );
}
